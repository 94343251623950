<template>
  <div 
    style="display: flex; justify-content: center; height: 100%; padding: 20px;"
    >
  <img
    class="a-venture-logo object-contain"
    style="width: 250px;"
    :src="logo"
    :alt="alt"
  />
</div> 
</template>

<script>
import useGetVentureProp from "@/composables/useGetVentureProp";

export default {
  name: "VentureLogo",
  setup() {
    const { logo } = useGetVentureProp("logo");
    const { name } = useGetVentureProp("name");

    return {
      alt: name,
      logo,
    };
  },
};
</script>
